a, a:hover, a:focus, a:active {
  text-decoration: none;
  color: inherit;
}

body {
    background-color: #000000;
    font-size: 2rem;
    font-family: monospace;
    color: #0f0;
}

.TopTracks {
    text-align: center;
}

.rabbit {
    font-size: 4rem;
    text-align: center;
    padding-top: 50px;
}
